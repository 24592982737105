// 基础路由
const baseRouter = [{
  path: '/home',
  component: (resolve) => require(['@/views/home'], resolve),
  name: 'home',
  meta: {
    title: 'home',
    icon: '',
    affix: true,
    zhTitle: '首页'
  }
},{
  path: '/details',
  component: (resolve) => require(['@/views/details'], resolve),
  name: 'details',
  meta: {
    title: 'details',
    icon: '',
    affix: true,
    zhTitle: '详情'
  }
},{
  path: '/faqs',
  component: (resolve) => require(['@/views/faqs'], resolve),
  name: 'faqs',
  meta: {
    title: 'faqs',
    icon: '',
    affix: true,
    zhTitle: 'FAQS'
  }
},{
  path: '/donate',
  component: (resolve) => require(['@/views/donate'], resolve),
  name: 'donate',
  meta: {
    title: 'donate',
    icon: '',
    affix: true,
    zhTitle: 'DONATE'
  }
},{
  path: '/learnMore',
  component: (resolve) => require(['@/views/learnMore'], resolve),
  name: 'learnMore',
  meta: {
    title: 'learnMore',
    icon: '',
    affix: true,
    zhTitle: 'learnMore'
  }
},{
  path: '/PrivacyStatement',
  component: (resolve) => require(['@/views/PrivacyStatement'], resolve),
  name: 'PrivacyStatement',
  meta: {
    title: 'PrivacyStatement',
    icon: '',
    affix: true,
    zhTitle: 'PrivacyStatement'
  }
},

{
  path: '/termsServices',
  component: (resolve) => require(['@/views/termsServices'], resolve),
  name: 'termsServices',
  meta: {
    title: 'termsServices',
    icon: '',
    affix: true,
    zhTitle: 'termsServices'
  }
},

]
export default baseRouter
