export default {
  route: {
    home: 'Home',
    dashboard: 'Dashboard',
    onlineDev: 'OnlineDev',
    'onlineDev-webDesign': 'WebDesign',
    'onlineDev-appDesign': 'APPDesign',
    'onlineDev-dataReport': 'DataReport',
    'onlineDev-dataScreen': 'DataScreen',
    'onlineDev-visualPortal': 'VisualPortal',
    generator: 'Generator',
    'generator-webForm': 'WebForm',
    'generator-appForm': 'AppForm',
    'generator-flowForm': 'FlowForm',
    system: 'System',
    'system-sysConfig': 'SysConfig',
    'system-notice': 'Notice',
    'system-task': 'Task',
    'system-cache': 'CacheManage',
    'system-log': 'Log',
    'system-monitor': 'Monitor',
    'system-icons': 'Icons',
    'system-language': 'Language',
    'system-menu': 'Menu',
    'system-area': 'Area',
    'system-billRule': 'BillRule',
    systemData: 'SystemData',
    'systemData-dataSource': 'DataSource',
    'systemData-dataModel': 'DataModel',
    'systemData-dataSync': 'DataSync',
    'systemData-dataBackup': 'DataBackup',
    'systemData-dataInterface': 'DataInterface',
    'systemData-dictionary': 'Dictionary',
    'systemData-map': 'Map',
    weChat: 'WeChat',
    'weChat-mpConfig': 'MPConfig',
    'weChat-mpMenu': 'MPMenu',
    'weChat-mpUser': 'MPUser',
    'weChat-mpMessage': 'MPMessage',
    'weChat-mpMaterial': 'MPMaterial',
    'weChat-qyhConfig': 'QYHConfig',
    'weChat-qyDepartment': 'QYDepartment',
    'weChat-qyUser': 'QYUser',
    'weChat-qyMessage': 'QYMessage',
    permission: 'Permission',
    'permission-organize': 'Organize',
    'permission-department': 'Department',
    'permission-position': 'Position',
    'permission-user': 'User',
    'permission-role': 'Role',
    'permission-authorize': 'Authorize',
    'permission-userOnline': 'UserOnline',
    workFlow: 'WorkFlow',
    'workFlow-flowEngine': 'FlowEngine',
    'workFlow-flowLaunch': 'FlowLaunch',
    'workFlow-flowTodo': 'FlowTodo',
    'workFlow-flowDone': 'FlowDone',
    'workFlow-flowCirculate': 'FlowCirculate',
    'workFlow-entrust': 'Entrust',
    'workFlow-flowMonitor': 'FlowMonitor',
    'extend-graphDemo': 'Graph Demo',
    'extend-graphDemo-echartsBar': 'E-Bar',
    'extend-graphDemo-echartsPie': 'E-Pie',
    'extend-graphDemo-echartsBarAcross': 'E-BarAcross',
    'extend-graphDemo-echartsGauge': 'E-Gauge',
    'extend-graphDemo-echartsLineArea': 'E-LineArea',
    'extend-graphDemo-echartsScatter': 'E-Scatter',
    'extend-graphDemo-echartsCandlestick': 'E-Candlestick',
    'extend-graphDemo-echartsLineBar': 'E-LineBar',
    'extend-graphDemo-echartsTree': 'E-Tree',
    'extend-graphDemo-echartsFunnel': 'E-Funnel',
    'extend-graphDemo-highchartsScatter': 'H-Scatter',
    'extend-graphDemo-highchartsWordcloud': 'H-Wordcloud',
    'extend-graphDemo-highchartsLine': 'H-Line',
    'extend-graphDemo-highchartsArea': 'H-Area',
    'extend-graphDemo-highchartsGauge': 'H-Gauge',
    'extend-graphDemo-highchartsBellcurve': 'H-Bellcurve',
    'extend-graphDemo-highchartsFunnel': 'H-Funnel',
    'extend-graphDemo-highchartsBullet': 'H-Bullet',
    'extend-graphDemo-highchartsColumn': 'H-Column',
    'extend-graphDemo-highchartsPie': 'H-Pie',
    'extend-tableDemo': 'Table Demo',
    'extend-tableDemo-commonTable': 'CommonTable',
    'extend-tableDemo-statisticsTable': 'StatisticsTable',
    'extend-tableDemo-lockTable': 'LockTable',
    'extend-tableDemo-groupingTable': 'GroupingTable',
    'extend-tableDemo-redactTable': 'RedactTable',
    'extend-tableDemo-complexHeader': 'ComplexHeader',
    'extend-tableDemo-mergeTable': 'MergeTable',
    'extend-tableDemo-printTable': 'PrintTable',
    'extend-tableDemo-extension': 'Extension',
    'extend-tableDemo-treeTable': 'TreeTable',
    'extend-tableDemo-postilTable': 'PostilTable',
    'extend-tableDemo-tableTree': 'TableTree',
    'extend-tableDemo-signTable': 'SignTable',
    'extend-formDemo': 'Form Demo',
    'extend-formDemo-verifyForm': 'VerifyForm',
    'extend-formDemo-verifyForm1': 'VerifyForm1',
    'extend-formDemo-fieldForm1': 'FieldForm1',
    'extend-formDemo-fieldForm2': 'FieldForm2',
    'extend-formDemo-fieldForm3': 'FieldForm3',
    'extend-formDemo-fieldForm4': 'FieldForm4',
    'extend-formDemo-fieldForm5': 'FieldForm5',
    'extend-formDemo-fieldForm6': 'FieldForm6',
    extend: 'Extend',
    'extend-bigData': 'BigData',
    'extend-importAndExport': 'ImportAndExport',
    'extend-signet': 'Signet',
    'extend-signature': 'Signature',
    'extend-schedule': 'Schedule',
    'extend-email': 'Email',
    'extend-document': 'Document',
    'extend-documentPreview': 'DocumentPreview',
    'extend-barCode': 'BarCode',
    'extend-printData': 'PrintData',
    'extend-map': 'Map',
    'extend-order': 'Order',
    'extend-projectGantt': 'ProjectGantt',
    moreMenu: 'More...',
    dataReport: 'DataReport Demo',
    reportBI: 'ReportBI Demo',
  },
  navbar: {
    profile: 'Profile',
    feedback: 'Feedback',
    about: 'About',
    lockScreen: 'Lock Screen',
    documentation: 'Documentation',
    logOut: 'Log Out',
    notice: 'Notice',
    chat: 'Chat',
    setting: 'Setting',
  },
  login: {
    title: 'Account Login',
    scanTitle: 'Scan Login',
    codeTitle: 'Verification Code Login',
    logIn: 'Login',
    username: 'Username / Mobile Number',
    password: 'Password',
    rule: 'Sub Account: mobile@account example:18577778888@101001',
    version: 'V',
    upper: 'Caps locked',
    scanTip: 'APP Scan code login',
    accountTip: 'Please enter the account number',
    passwordTip: 'Please enter your password',
    mobile: 'Please enter mobile number',
    rightMobile: 'Please enter the correct mobile number',
    smsCode: 'Please enter the verification code',
    getCode: 'Get code',
    reSend: 'Resend',
    company: 'Please enter company name',
    contacts: 'Please enter contact',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  settings: {
    mode: 'NavigationMode',
    classic: 'Classic',
    functional: 'Functional',
    plain: 'Plain',
    lightOrDark: 'Light&Dark',
    dark: 'Dark',
    lightWhite: 'Light',
    theme: 'ThemeColor',
    blue: 'Blue',
    purple: 'Purple',
    azure: 'Azure',
    ocean: 'Ocean',
    green: 'Green',
    yellow: 'Yellow',
    orange: 'Orange',
    red: 'Red',
    otherSet: 'OtherSettings',
    tagsView: 'Open Tags-View',
    tagsViewIcon: 'Show Tags-View Icon',
    language: 'Show Language Select',
    search: 'Show Search',
  },
  common: {
    addBtn: 'Add',
    editBtn: 'Edit',
    delBtn: 'Del',
    moreBtn: 'More',
    search: 'Search',
    reset: 'Reset',
    refresh: 'Refresh',
    columnSettings: 'Column Settings',
    fullScreen: 'Full Screen',
    outFullScreen: 'Out Full Screen',
    confirmButton: 'Ok',
    cancelButton: 'Cancel',
    delTip: 'This operation will permanently delete the data. Do you want to continue?',
    loadingText: 'Data Loading',
    noData: 'No Data',
    noPerTip: 'Permission Denied',
    tipTitle: 'Tips',
    prev: 'Prev',
    next: 'Next',
    keyWord: 'Keyword',
    enterKeyword: 'Please Enter',
    organization: 'Organization',
  },
  Organize: {
    addCompany: 'Add Company',
    editCompany: 'Edit Company',
  },
  department: {
    addDepartment: 'Add Department',
    editDepartment: 'Edit Department',
  },
  position: {
    newPost: 'New Position',
    editPost: 'Edit Position',
    postMember: 'Post Member',
    postPermission: 'Post Permission',
  },
  user: {
    addUser: 'Add User',
    editUser: 'Edit User',
    resetPassword: 'Reset Password',
    userPermission: 'User Permission',
  },
  role: {
    addRole: 'Add Role',
    editRole: 'Edit Role',
    roleMember: 'Role Member',
    rolePermission: 'Role Permission',
  },
  authorize: {
    operation: 'Operation',
    checkAll: 'Check All',
    deselectAll: 'Deselect All',
    expandAll: 'Expand All',
    foldAll: 'Fold All',
    menuPermission: 'Menu Permission',
    buttonPermission: 'Button Permission',
    listPermission: 'List Permission',
    dataPermission: 'Data Permission',
    organizePermission: 'Organize Permission',
    selectRoles: 'Select Role',
  },
  userOnline: {
    cancelAccountTip: 'Do you want to cancel the account?',
    forcedOffline: 'Offline',
  },
  index: {
    learnMore: 'Learn More',
    contentText1:
      'NFTSwag is a platform focused on linking virtual NFT with the real world IRL. NFTSwag is dedicated to developing practical features and IP derivatives of blue-chip NFT and introducing NFT to the real offline fashion world, while using blockchain OPT technology to allow more ordinary people to join the NFT world.',
    contentText2:
      'We rely on professional design and manufacturing expertise, IP operational capabilities to create unique experiences with physical fashion, sneakers, backpacks, hoodies, skateboards and digital artifacts to move more people from Web2 to Web3.',
    contentText3:
      'OPT is a badge of honor or proof of purchase. Purchases of physical products will receive a badge of honor NFTswag OPT is a lightweight badge of proof designed for IRL (NFT IP) world product purchasers to easily build and distribute NFT badges and create NFTs through the on-chain credential data network to experience NFT life and reward NFTSWAG community members.',
    contentText4:
      'OPT is both proof of purchase and a pass, and OPT holders have the opportunity to cast a unique NFT once a year, which is designed by artists from around the world.',
    contentText5:
      'We fragment each blue-chip NFT, also known as Fractional NFTs or F-NFTs. Each product of NFTSWAG is uniquely customized and available in limited quantities. By purchasing a product, participants mean that you will have ownership of a certain percentage of NFTs (including: nft value, voting rights, minting nft, access to opt token airdrop, copyright proceeds, etc.)',
    contentText6:
      'NFTSWAG offline brick-and-mortar store brings together a series of NFT IP derivatives such as BAYC, MAYC, CRYPTOPUNKS, MEEBITS and AZUKI. We initially plan to open the first offline physical store in Manhattan, New York, and later plan to open several NFT derivatives stores in London, Toronto, Singapore, Dubai, Hong Kong, etc.',
    contentText7: 'Issuance of governance token OPT, which is airdropped according to the share of OPT NFT held.',
    logoListLetter: 'Unleash the power of community to create and build thriving ecosystems',
    logoListCon:
      'Involving the community in the development and operation of the Blue Chip NFT series of derivatives! Working together with the community and NFT project parties to develop the Blue Chip NFT series derivatives.',
  },
  faqs: {
    title1: 'About NFTSWAG',
    content1: `NFTSwag is a platform focused on linking virtual NFT with the real world IRL. NFTSwag is dedicated to developing practical features and IP derivatives of blue-chip NFT and bringing NFT into the real offline fashion world, while leveraging blockchain OPT technology to bring more ordinary people into the NFT world.
    We rely on our professional design and manufacturing expertise, IP operation capabilities to create unique experiences with object/n-rational fashion, sneakers, backpacks, hoodies, skateboards and digital artifacts to bring more people from Web2 into Web3.`,
  },
  learnMore: {
    txtContent1: `    NFTSWAG was founded by experienced software architects, crypto industry professionals, and top IP operations professionals.

    NFTSwag is a platform focused on linking virtual NFT to real world IRL, driven and operated by the founding team in the early stages, and will be driven and operated by the NFTSwa community in the later stages.

    NFTSwag is dedicated to developing practical features and IP derivatives of blue-chip NFT and bringing NFT to the real offline fashion world, while using blockchain technology to bring more ordinary people into the NFT world.

    We rely on our professional design and manufacturing expertise, IP operation capabilities to create unique experiences with physical fashion, sneakers, backpacks, hoodies, skateboards and digital artifacts to bring more people from Web2 into Web3.`,
    txtContent2: `   NFTSWAG's mission is to accelerate the mass adoption of NFTs to enable personal entry into the world of cryptocurrencies and the wonderful world of NFTs.

    Security, decentralization, community ownership are core to our success.

    This could take years, but we’re all in it for the long haul.`,
    txtContent3: `<h3>High degree of ownership?</h3>
    <span>We value a high degree of responsibility and ownership: every task, project, or area of expertise has exactly one owner. The team is run by results,
      not hierarchy, and the best ideas win.</span>
    <h3>Transparency and trust at the core</h3>
    <span>Transparency and trust is the backbone of operating effectively together and we assume complete trust between each other and respect each other’s
      capabilities.</span>
    <h3>Not afraid to set impossible goals</h3>
    <span>We make impossible things work. We think long term and we think big. We empower everyone at NFTSWAG to set ambitious goals.</span>
    <h3>Join our team</h3>
    <span>contact: privacy@nftswag.cc </span>`,
  },
}
