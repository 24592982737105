<template>
    <component :is="layoutType" />
</template>

<script>
import classic from './classic/index.vue'
import mobile from './mobile/index.vue'
export default {
    name: 'WebObjIndex',
    components:{classic,mobile},
    data() {
        return {
            layoutType:''
        };
    },

    mounted() {
        this.changeLayoutType()
    },

    methods: {
        changeLayoutType(){
            console.log(window.innerWidth)
            if(window.innerWidth <= 800){
                    this.layoutType='mobile'
                } else {
                    this.layoutType='classic'
                }
            window.addEventListener("resize", () => {
                if(window.innerWidth <= 800){
                    this.layoutType='mobile'
                } else {
                    this.layoutType='classic'
                }
               console.log(window.innerWidth)  
            });
        }
    },
};
</script>

<style lang="scss" scoped>

</style>