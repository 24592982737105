import Vue from 'vue'


const jnpf = require('./utils/jnpf').default
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './lang' // internationalization


import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(Element, {
  size: jnpf.storageGet('size') || 'small', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(Element);

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default Vue
