<template>
    <div>
        <Header/>
        <app-main />
        <Floor />
    </div>
</template>

<script>
import {AppMain, Header,Floor} from "./index";
export default {
    name: 'WebObjIndex',
    components:{Header,AppMain,Floor},
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>