<template>
  <div class="content">
    <div class="header-content">
      <div class="left-logo" @click="toHome">
        <!-- <div class="logo-img"> -->
        <img src="@/assets/logo-long.png" alt="">
        <!-- </div> -->
        <!-- <div class="logo-name">
        NFTSWAG
      </div> -->
      </div>
      <div class="menu-nav">
        <div class="nav-list" v-for="(item, index ) in menuList" @click="menuListClick('parent', index, -1)" @mouseleave="hiddenMenuList(index)"
          @mouseover="showMenuList(index)" :key="index">
          {{ item.name }}
          <i v-if="item.children" class="el-icon-arrow-down down-icon"></i>
          <div class="lit-menu" v-if="item.children && item.isShow">
            <div class="lit-menu-item" v-for="(it, inde) in item.children" :key="inde" @click="menuListClick('child', index, inde)">{{ it.name }}</div>
          </div>

        </div>
        <!-- <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        unique-opened
        menu-trigger="hover"
        mode="horizontal"
        @select="handleSelect"
      > 
      <template v-for="(item,index ) in menuList">
        <el-menu-item v-if="item.children==null" :key="index" :index="index+''">{{item.name}}</el-menu-item>
        <el-submenu :key="index" :index="index+''" v-else>
          <template slot="title">{{item.name}}</template>
          <el-menu-item  v-for="(it , inde) in item.children" :key="inde" :index="index+'-'+inde">{{it.name}}</el-menu-item>
        </el-submenu>
       
      </template>
      </el-menu> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebObjHeader",

  data () {
    return {
      activeIndex: "1",
      menuList: [
        {
          name: "Learn More",
          value: "0",
          isShow: false,
          children: null,
          children: [
            { name: "LearnMore", value: "/learnMore" },
            { name: "Shop NFTSwag", value: "0" },
            { name: "Earn OPT", value: "1" },
            { name: "OPT Token", value: "2" },
            // { name: "EIP-1559", value: "3" },
          ],
        },
        {
          name: "Support",
          value: "1",
          isShow: false,
          children: [
            { name: "FAQs", value: "/faqs" },
            { name: "Get Support", value: "1" },
            { name: "Community", value: "2" },
            { name: "Donate", value: "/donate" },
          ],
        },
        {
          name: "About",
          value: "2",
          isShow: false,
          children: [
            { name: "Team", value: "0" },
            { name: "Careers", value: "1" },
            { name: "News", value: "2" },
          ],
        },
        {
          name: "Roadmap",
          value: "3",
          isShow: false,
          children: [
            { name: "Roadmap", value: "0" },
            // { name: "Developers Docs", value: "0" },
            // { name: "Institutions", value: "1" },
            // { name: "Snaps", value: "2" },
            // { name: "Flask", value: "3" },
            // { name: "SDK", value: "4" },
          ],
        },
      ],
    };
  },

  mounted () { },

  methods: {
    toHome () {
      this.$router.push({ path: '/home' })
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    },
    menuListClick (type, pid, cid) {
      console.log('点击了:', type, this.menuList[pid].name, cid === -1 ? 'null' : this.menuList[pid].children[cid].name)
      if (type === 'parent' && this.menuList[pid].children) {
        this.menuList[pid].isShow = !this.menuList[pid].isShow
      } else {
        if (this.menuList[pid].children[cid].value.length > 1) {
          this.$router.push(this.menuList[pid].children[cid].value)
        }
      }
    },
    hiddenMenuList (i) {
      this.menuList.map((item, index) => {
        return item.isShow = false
      })
    },
    showMenuList (i) {
      this.menuList.map((item, index) => {
        if (index == i) {
          return item.isShow = true
        }
        else {
          return item.isShow = false
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 999999;
}

.header-content {
  display: flex;
  align-items: center;
  max-width: 992px;
  min-width: 800px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
  border-bottom-color: transparent;
}

::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  border-bottom-color: transparent;
}

.left-logo {
  width: 300px;
  display: flex;
  align-items: center;

  .logo-img {
    width: 60px;

    img {
      width: 100%;
    }
  }

  .logo-name {
    padding-left: 20px;
    font-size: 22px;
    letter-spacing: 5px;
    font-weight: 600;

  }
}

.left-logo:hover {
  cursor: pointer;
}

.menu-nav {
  display: flex;
  position: relative;

  .nav-list {
    padding: 20px;
    cursor: pointer;
    font-size: 14px;

    .down-icon {
      font-weight: 600;
    }
  }

  .nav-list:hover {
    color: #037DD6;
  }

  .lit {
    position: relative;
  }

  .lit-menu {
    min-width: 110px;
    position: absolute;
    background: #fff;
    color: #000;
    padding: 5px;
    margin-top: 15px;
    margin-left: -20px;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    .lit-menu-item {
      padding: 10px 8px;
      white-space: nowrap;
      border-radius: 3px;
    }

    .lit-menu-item:hover {
      background-color: #E6EAEE;
      color: #037DD6;
    }
  }
}
</style>