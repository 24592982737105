<template>
<div class="content">
  <div class="header-content">
    <div class="left-logo" @click="toHome">
      <!-- <div class="logo-img"> -->
        <img src="@/assets/logo-long.png" alt="">
      <!-- </div> -->
      <!-- <div class="logo-name">
        NFTSWAG
      </div> -->
    </div>
    <div class="icon-menu">
      <img src="@/assets/menu.png" @click="isShowMenu = !isShowMenu" alt="">
    </div>
  </div>
  <el-dialog title="提示" :visible.sync="isShowMenu" :modal="false" width="100%" :show-close="false" top="50px">
    <div slot="title" class="title-show"></div>
    <div class="menu-nav" v-show="isShowMenu">
      <el-menu
       style="width:100%"
        :default-active="activeIndex"
        class="el-menu-demo"
        unique-opened
        menu-trigger="hover"
        @select="handleSelect"
      > 
      <template v-for="(item,index ) in menuList">
        <el-menu-item v-if="item.children==null" :key="index" :index="index+''">{{item.name}}</el-menu-item>
        <el-submenu :key="index" :index="index+''" v-else>
          <template slot="title">{{item.name}}</template>
          <el-menu-item  v-for="(it , inde) in item.children" :key="inde" :index="index+'-'+inde">{{it.name}}</el-menu-item>
        </el-submenu>

      </template>
    </el-menu>
  </div>
</el-dialog>



  </div>
</template>

<script>
export default {
  name: "WebObjHeader",

  data() {
    return {
      activeIndex: "1",
      isShowMenu:false,
      menuList: [
        {
          name: "Learn More",
          value: "0",
          isShow:false,
          children: null,
          children: [
            { name: "Shop NFTSwag", value: "0" },
            { name: "Earn OPT", value: "1" },
            { name: "OPT Token", value: "2" },
            // { name: "EIP-1559", value: "3" },
          ],
        },
        {
          name: "Support",
          value: "1",
          isShow:false,
          children: [
            { name: "FAQs", value: "0" },
            { name: "Get Support", value: "1" },
            { name: "Community", value: "2" },
          ],
        },
        {
          name: "About",
          value: "2",
          isShow:false,
          children: [
            { name: "Team", value: "0" },
            { name: "Careers", value: "1" },
            { name: "News", value: "2" },
          ],
        },
        {
          name: "Roadmap",
          value: "3",
          isShow:false,
          children: [
            { name: "Roadmap", value: "0" },
            // { name: "Developers Docs", value: "0" },
            // { name: "Institutions", value: "1" },
            // { name: "Snaps", value: "2" },
            // { name: "Flask", value: "3" },
            // { name: "SDK", value: "4" },
          ],
        },
      ],
    };
  },

  mounted() {},

  methods: {
    toHome(){
      this.$router.push({path:'/home'})
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    menuListClick(i){
      console.log('点击了'+this.menuList[i].name)
      if(this.menuList[i].children){
        this.menuList[i].isShow = !this.menuList[i].isShow
      }
    },
    hiddenMenuList(i){
      this.menuList.map((item,index)=>{
        return item.isShow = false
      })
    },
    showMenuList(i){
      console.log(i)
      this.menuList.map((item,index)=>{
        if(index==i){
          return item.isShow = true
        }
        else{
          return item.isShow = false
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  background-color:#fff ;
  position: fixed;
  top: 0;
  z-index: 999999;
  ::v-deep .el-dialog__header{
     padding: 0;
  }
  ::v-deep .el-dialog {
    box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
}
.header-content {
  display: flex;
  align-items: center;
  max-width: 991px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}
.el-menu{
  border-right: none;
  border-right-color: transparent;
}
.left-logo{
  width: 219px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  .logo-img{
    width: 60px;
    img{
      width: 100%;
    }
  }
  .logo-name{
    padding-left: 20px;
    font-size: 22px;
    letter-spacing:5px;
    font-weight: 600;
    
  }
}

.menu-nav{
  width: 100%;
  max-width: 991px;
  display: flex;
  position: relative;

}
.icon-menu{
  width: 40px;
  height: 40px;
  padding: 5px;
  img{
    width: 100%;
  }
}
.title-show{
  height: 0;
}
</style>
