<template>
  <div class="content">
    <div class="safety-zone">
      <div class="left-logo">
        <div class="logo-img">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="logo-name">NFTSWAG</div>
      </div>
      <div class="link-list">
        <div v-for="(item, index) in bottomContent" :key="index">
          <div class="tittle">{{ item.tittle }}</div>
          <div class="link-cont">
            <div v-for="(iteml, inde) in item.children" :key="inde" class="link-name">
              {{ iteml.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="website-info">
        Copyright @2023 NFTSWAG
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebObjFloor",

  data () {
    return {
      bottomContent: [
        {
          tittle: "LEARN MORE", children: [
            { name: 'NFTSWAG', url: '' },
            { name: 'OPT', url: '' },
            { name: 'Roadmap', url: '' }]
        },
        {
          tittle: "GET INVOLVED", children: [
            { name: 'NFTSwag Shop', url: '' },
            { name: 'NFTSwag DAO', url: '' },
            { name: 'Metaverse Online Store', url: '' },
            { name: 'Offline Entity Store', url: '' }]
        },
        {
          tittle: "CONNECT", children: [
            { name: 'FAQS', url: '/faqs' },
            { name: 'Support', url: '' },
            { name: 'News', url: '' },
            { name: 'Twitter', url: '' },
            { name: 'Discord', url: '' },
            { name: 'Instagram', url: '' }]
        },
        {
          tittle: "LEGAL", children: [
            { name: 'Privacy Policy', url: '/PrivacyStatement' },
            { name: 'Terms of Use', url: '/termsServices' }]
        },
      ]
    };
  },

  mounted () { },

  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #e9ebee;

  .safety-zone {
    width: 100%;
    max-width: 992px;
    min-width: 800px;
    overflow: hidden;
    margin: 0 auto;
    padding: 60px 0;
  }
}

.left-logo {
  width: 300px;
  display: flex;
  align-items: center;

  .logo-img {
    width: 50px;

    img {
      width: 100%;
    }
  }

  .logo-name {
    padding-left: 20px;
    font-size: 18px;
    letter-spacing: 5px;
    font-weight: 600;

  }
}

.link-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;

  .tittle {
    font-size: 18px;
    color: #F6871E;
    margin-top: 40px;
  }

  .link-cont {
    padding: 20px 0;

    .link-name {
      font-size: 12px;
      line-height: 20px;
      padding: 5px 0;
      color: #475263;
    }
  }

}

.website-info {
  font-size: 12px;
  line-height: 20px;
  padding: 5px 0;
  color: #475263;
}
</style>