export default {
  route: {
    "home": "首頁",
    "dashboard": "門戶展示",
    "onlineDev": "在線開發",
    "onlineDev-webDesign": "功能設計",
    "onlineDev-appDesign": "移動設計",
    "onlineDev-dataReport": "報表設計",
    "onlineDev-dataScreen": "大屏設計",
    "onlineDev-visualPortal": "門戶設計",
    "generator": "代碼生成",
    "generator-webForm": "功能表單",
    "generator-appForm": "移動表單",
    "generator-flowForm": "流程表單",
    "system": "系統管理",
    "system-sysConfig": "系統配置",
    "system-notice": "系統公告",
    "system-task": "系統調度",
    "system-cache": "系統緩存",
    "system-log": "系統日誌",
    "system-monitor": "系統監控",
    "system-icons": "系統圖標",
    "system-language": "翻譯管理",
    "system-menu": "菜單管理",
    "system-area": "行政區劃",
    "system-billRule": "單據規則",
    "systemData": "數據應用",
    "systemData-dataSource": "數據連接",
    "systemData-dataModel": "數據建模",
    "systemData-dataSync": "數據同步",
    "systemData-dataBackup": "數據備份",
    "systemData-dataInterface": "數據接口",
    "systemData-dictionary": "數據字典",
    "systemData-map": "數據地圖",
    "weChat": "微信配置",
    "weChat-mpConfig": "公眾號配置",
    "weChat-mpMenu": "公眾號菜單",
    "weChat-mpUser": "公眾號用戶",
    "weChat-mpMessage": "公眾號消息",
    "weChat-mpMaterial": "公眾號素材",
    "weChat-qyhConfig": "企業號配置",
    "weChat-qyDepartment": "企業號組織",
    "weChat-qyUser": "企業號用戶",
    "weChat-qyMessage": "企業號消息",
    "permission": "系統權限",
    "permission-organize": "組織管理",
    "permission-department": "部門管理",
    "permission-position": "崗位管理",
    "permission-user": "用戶管理",
    "permission-role": "角色管理",
    "permission-authorize": "權限管理",
    "permission-userOnline": "在線用戶",
    "workFlow": "工作流程",
    "workFlow-flowEngine": "流程設計",
    "workFlow-flowLaunch": "我發起的",
    "workFlow-flowTodo": "待辦事宜",
    "workFlow-flowDone": "已辦事宜",
    "workFlow-flowCirculate": "抄送事宜",
    "workFlow-entrust": "流程委托",
    "workFlow-flowMonitor": "流程監控",
    "extend-graphDemo": "圖表示例",
    "extend-graphDemo-echartsBar": "E-柱狀圖",
    "extend-graphDemo-echartsPie": "E-餅狀圖",
    "extend-graphDemo-echartsBarAcross": "E-橫狀條形圖",
    "extend-graphDemo-echartsGauge": "E-儀表圖",
    "extend-graphDemo-echartsLineArea": "E-線形圖",
    "extend-graphDemo-echartsScatter": "E-散點圖",
    "extend-graphDemo-echartsCandlestick": "E-K線圖",
    "extend-graphDemo-echartsLineBar": "E-折柱混合圖",
    "extend-graphDemo-echartsTree": "E-樹形圖",
    "extend-graphDemo-echartsFunnel": "E-漏鬥圖",
    "extend-graphDemo-highchartsScatter": "H-散點圖",
    "extend-graphDemo-highchartsWordcloud": "H-詞雲圖",
    "extend-graphDemo-highchartsLine": "H-線性圖",
    "extend-graphDemo-highchartsArea": "H-面積圖",
    "extend-graphDemo-highchartsGauge": "H-儀表圖",
    "extend-graphDemo-highchartsBellcurve": "H-貝爾曲線",
    "extend-graphDemo-highchartsFunnel": "H-漏鬥圖",
    "extend-graphDemo-highchartsBullet": "H-子彈圖",
    "extend-graphDemo-highchartsColumn": "H-柱狀圖",
    "extend-graphDemo-highchartsPie": "H-餅狀圖",
    "extend-tableDemo": "表格示例",
    "extend-tableDemo-commonTable": "普通表格",
    "extend-tableDemo-statisticsTable": "表格統計",
    "extend-tableDemo-lockTable": "表格鎖定",
    "extend-tableDemo-groupingTable": "表格分組",
    "extend-tableDemo-redactTable": "表格編輯",
    "extend-tableDemo-complexHeader": "復雜表頭",
    "extend-tableDemo-mergeTable": "表格合並",
    "extend-tableDemo-printTable": "表格打印",
    "extend-tableDemo-extension": "延伸擴展",
    "extend-tableDemo-treeTable": "樹形表格",
    "extend-tableDemo-postilTable": "表格批註",
    "extend-tableDemo-tableTree": "表格樹形",
    "extend-tableDemo-signTable": "表格標記",
    "extend-formDemo": "表單示例",
    "extend-formDemo-verifyForm": "表單驗證",
    "extend-formDemo-verifyForm1": "表單驗證1",
    "extend-formDemo-fieldForm1": "表單字段1",
    "extend-formDemo-fieldForm2": "表單字段2",
    "extend-formDemo-fieldForm3": "表單字段3",
    "extend-formDemo-fieldForm4": "表單字段4",
    "extend-formDemo-fieldForm5": "表單字段5",
    "extend-formDemo-fieldForm6": "表單字段6",
    "extend": "擴展應用",
    "extend-bigData": "百萬數據",
    "extend-importAndExport": "導入導出",
    "extend-signet": "電子簽章",
    "extend-signature": '電子簽名',
    "extend-schedule": "日程安排",
    "extend-email": "郵件收發",
    "extend-document": "知識管理",
    "extend-documentPreview": "文檔預覽",
    "extend-barCode": "條碼示例",
    "extend-printData": "打印示例",
    "extend-map": "地圖示例",
    "extend-order": "訂單管理",
    "extend-projectGantt": "項目管理",
    "moreMenu": "更多...",
    "dataReport": "報表示例",
    "reportBI": "大屏示例",
  },
  navbar: {
    profile: '個人信息',
    feedback: '反饋問題',
    about: '關於平臺',
    lockScreen: '鎖住屏幕',
    documentation: '幫助文檔',
    logOut: '退出系統',
    notice: '消息',
    chat: '聊天',
    setting: '設置'
  },
  login: {
    title: '賬戶登錄',
    scanTitle: '掃碼登錄',
    codeTitle: '驗證碼登錄',
    logIn: '登錄',
    username: '請輸入用戶名/手機號',
    password: '請輸入密碼',
    rule: '子賬戶規則：手機號@賬戶 例：18577778888@101001',
    version: '版本',
    upper: '大寫已鎖定',
    scanTip: 'APP掃碼登錄',
    accountTip: '請輸入賬號',
    passwordTip: '請輸入密碼',
    mobile: '請輸入手機號',
    rightMobile: '請輸入正確的手機號',
    smsCode: '請輸入驗證碼',
    getCode: '獲取驗證碼',
    reSend: '重新發送',
    company: '請輸入公司名',
    contacts: '請輸入聯系人'
  },
  tagsView: {
    refresh: '刷新',
    close: '關閉',
    closeOthers: '關閉其它',
    closeAll: '關閉所有'
  },
  settings: {
    mode: '導航模式',
    classic: '經典款',
    functional: '功能款',
    plain: '簡約款',
    lightOrDark: '顏色區塊',
    dark: '暗黑模式',
    lightWhite: '亮白模式',
    theme: '主題顏色',
    blue: '經典藍',
    purple: '玫紫',
    azure: '湛藍',
    ocean: '海洋',
    green: '生機',
    yellow: '豐收',
    orange: '陽橙',
    red: '熱情',
    otherSet: '其他設置',
    tagsView: '開啟多頁簽',
    tagsViewIcon: '多頁簽顯示圖標',
    language: '顯示多語言選擇',
    search: '顯示頁面搜索框'
  },
  common: {
    addBtn: '新建',
    editBtn: '編輯',
    delBtn: '刪除',
    moreBtn: '更多',
    search: '查詢',
    reset: '重置',
    refresh: '刷新',
    columnSettings: '列設置',
    fullScreen: '全屏',
    outFullScreen: '退出全屏',
    confirmButton: '確 定',
    cancelButton: '取 消',
    delTip: '此操作將永久刪除該數據, 是否繼續？',
    loadingText: '數據加載中',
    noData: '暫無數據',
    noPerTip: '暫無權限',
    tipTitle: '提示',
    prev: '上壹步',
    next: '下壹步',
    keyWord: '關鍵詞',
    enterKeyword: '請輸入關鍵詞查詢',
    organization: '組織機構'
  },
  Organize: {
    addCompany: '新建公司',
    editCompany: '編輯公司'
  },
  department: {
    addDepartment: '新建部門',
    editDepartment: '編輯部門',

  },
  position: {
    newPost: '新建崗位',
    editPost: '編輯崗位',
    postMember: '崗位成員',
    postPermission: '崗位權限'
  },
  user: {
    addUser: '新建用戶',
    editUser: '編輯用戶',
    resetPassword: '重置密碼',
    userPermission: '用戶權限'
  },
  role: {
    addRole: '新建角色',
    editRole: '編輯角色',
    roleMember: '角色成員',
    rolePermission: '角色權限',
  },
  authorize: {
    operation: '操作',
    checkAll: '全部勾選',
    deselectAll: '取消全選',
    expandAll: '展開所有',
    foldAll: '折疊所有',
    menuPermission: '菜單權限',
    buttonPermission: '按鈕權限',
    listPermission: '列表權限',
    dataPermission: '數據權限',
    organizePermission: '組織權限',
    selectRoles: '選擇角色',
    linkage: '父子聯動',
    quitLinkage: '取消聯動'
  },
  userOnline: {
    cancelAccountTip: '確定要註銷該賬號?',
    forcedOffline: '強制下線'
  }
}
