import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

import baseRouters from '@/router/modules/base'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [...baseRouters],
  },
]
// 解决：vue切换页面/路由跳转后，页面不会置顶
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition
  }
  // return { left: 0, top: 0 };
  return { x: 0, y: 0 }
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

export default router
