export default {
  route: {
    index: {
      learnMore: 'LEARN MORE',
      contentText1:
        'NFTSwag是一个聚焦于将虚拟的NFT和真实世界IRL链接的平台。NFTSwag致力于开发蓝筹NFT的实用功能和IP衍生品，并将NFT引入真实的线下时尚世界，同时利用区块链OPT技术，让更多的普通人加入NFT世界。',
      contentText2:
        '我们依靠专业的设计和制造专业知识，IP运作能力，用物理时尚、运动鞋、背包、帽衫、滑板和数字人工制品创造独特的体验，让更多的人从Web2 进入Web3。',
      contentText3:
        'OPT即荣誉勋章或者购买证明。购买实物产品将获得荣誉勋章NFTswag OPT是一种轻量级徽章证明,专为IRL（NFT IP）世界产品购买者设计,可通过链上凭证数据网络轻松构建分发NFT徽章并创建NFT,以体验NFT生活，并奖励NFTSWAG社区成员。',
      contentText4: 'OPT既是购买证明，又是通行证，OPT持有者有机会每年铸造一次独特的NFT，NFT由全球各地艺术家设计。',
      contentText5:
        '我们把每一个蓝筹NFT碎片化，也就是Fractional NFTs or F-NFTs。NFTSWAG的每一款产品都是独特定制，限量供应。参与者购买产品，就意味着您将享有一定比例的NFT的所有权(包括：nft价值、投票权、铸造nft、获得opt代币空投、版权收益等）',
      contentText6:
        'NFTSWAG线下实体商店，汇聚了BAYC、MAYC、CRYPTOPUNKS、MEEBITS、AZUKI等系列NFT IP衍生品。我们初期计划在纽约曼哈顿开设第一家线下实体商店，后期计划在伦敦、多伦多、新加坡、迪拜、香港等地开设多家NFT衍生品商店。',
      contentText7: '发行治理代币OPT，按持有OPT NFT的份额空投。',
      logoListLetter: '释放社区的力量来创建和建立繁荣的生态系统',
      logoListCon: '让社区参与蓝筹NFT系列衍生品的开发和运营！携手社区、NFT项目方共同开发蓝筹NFT系列衍生品。',
    },
    faqs: {
      title1: '关于 NFTSWAG',
      content1: `NFTSwag是一个聚焦于将虚拟的NFT和真实世界IRL链接的平台。NFTSwag致力于开发蓝筹NFT的实用功能和IP衍生品，并将NFT引入真实的线下时尚世界，同时利用区块链OPT技术，让更多的普通人加入NFT世界。
    我们依靠专业的设计和制造专业知识，IP运作能力，用物/n理时尚、运动鞋、背包、帽衫、滑板和数字人工制品创造独特的体验，让更多的人从Web2 进入Web3。`,
    },
    learnMore: {
      txtContent1: `    NFTSWAG由经验丰富的软件架构师、加密行业专业人士、顶级IP运营专业人士所创立。

    NFTSwag是一个聚焦于将虚拟的NFT和真实世界IRL链接的平台，前期由创始团队驱动和运营，后期将由NFTSwa 社区驱动和运营。

    NFTSwag致力于开发蓝筹NFT的实用功能和IP衍生品，并将NFT引入真实的线下时尚世界，同时利用区块链技术，让更多的普通人加入NFT世界。

    我们依靠专业的设计和制造专业知识，IP运作能力，用物理时尚、运动鞋、背包、帽衫、滑板和数字人工制品创造独特的体验，让更多的人从Web2 进入Web3。`,
      txtContent2: `   NFTSWAG的使命是加速大规模采用NFT以实现个人进入加密货币世界和精彩的NFT世界。

    NFTswag OPT是一种轻量级徽章证明,专为IRL（NFT IP）世界产品购买者设计,可通过链上凭证数据网络轻松构建分发NFT徽章并创建NFT,以体验NFT生活，并奖励NFTSWAG社区成员。

    安全、去中心化、社区所有权是我们成功的核心。

    这可能需要数年时间，但我们都在长期努力。
    `,
      txtContent3: `<h3>高度自主权?</h3>
      <span>我们高度重视责任和自主权：每个任务、项目或专业领域都有确切的负责人。团队的运作是以结果为导向，而不是以等级为导向，最好的想法会被采纳和执行。</span>
      <h3>透明度和自主权是项目的核心</h3>
      <span>透明度和自主权是有效运作的支柱，我们假设彼此之间完全信任，尊重对方的能力。</span>
      <h3>不怕设定不可能的目标</h3>
      <span>让我们把不可能的事情变成可能！我们长远计划，胸有成竹！我们授权NFTSWAG的每个人制定雄心勃勃的目标。</span>
      <h3>请加入我们</h3>
      <span>联系方式: privacy@nftswag.cc </span>`,
    },
  },
}
